import React, { FC , useEffect, useState} from 'react';
// import logo from "./logo.svg";

// require('@solana/wallet-adapter-react-ui/styles.css');
import {
    useConnection,
    useWallet,
} from "@solana/wallet-adapter-react";


import { SendTokens } from '../SendTokens/SendTokens';


import styled from "styled-components";
import { Paper, Button } from '@mui/material';
import { SelectApe } from "../Select/SelectApe";
import type { Options } from '@nfteyez/sol-rayz';

export const Upgrade = () => {

    const DesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 20px;
`;

    const NFT = styled(Paper)`
  padding: 20px 20px 20px 20px;
  flex: 1 1 auto;
  background-color: var(--card-background-color) !important;
  border-radius: 0px !important;
`;

    const Image = styled.img`
    height: 225px;
    width: auto;
    box-shadow: 5px 5px 40px 5px rgb(0 0 0 / 50%);
`;

    const CTAButton = styled(Button)`
    font-family: lores-12,sans-serif !important;
    font-weight: 400 !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: bold !important;
    -webkit-text-decoration: none !important;
    text-decoration: none !important;
    padding: 5px 30px !important;
    background-color: #512da8 !important;
    box-shadow: 0px 3px 0 0 #ffff00 !important;
    -webkit-transition: all 100ms ease !important;
    -webkit-transition: all 100ms ease !important;
    transition: all 100ms ease !important;
    -webkit-text-decoration: none !important;
    text-decoration: none !important;
    max-width: 350px !important;
    margin: auto !important;
    margin-top: 50px;

    &:hover {
        box-shadow: 0 0 0 0 #ffff00 !important;
        transform: translate(0px, 4px) !important;
    }
`;

    const MintContainer = styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        max-width: 800px;
        margin: auto;
    `;

    const [turtle, setLeft] = useState<string>("./turtle.jpg");
    const [potion, setRight] = useState<string>("./potion.jpg");
    const [selectedMint, setTurtleMint] = useState<string>("");
    const [selectedPotion, setPotionMint] = useState<string>("");
    const [leftClick, setLeftClick] = useState(false);
    const [popupIsVisible, togglePopup] = useState(false);
    const [upgradeDisabled, disableUpgrade] = useState(true);
    const [selectedType, setType] = useState<string>("");
    const [options, setOptions] = useState<Options>();

    const {connection} = useConnection();
    const {publicKey} = useWallet();

    const unselect = function() {
        setLeft("./turtle.jpg");
        setRight("./potion.jpg")
    }

    useEffect(() => {
        (async () => {
            if (connection && publicKey) {
                const optionsTemp: Options = {publicAddress: publicKey.toString(), connection: connection};
                console.log(publicKey.toString())
                setOptions(optionsTemp);
            }
        })();
    }, [connection, publicKey]);

    useEffect(() => {
        (async () => {
            if (selectedPotion !== '' && selectedMint !== '')
            disableUpgrade(false);
        })();
    }, [selectedPotion, selectedMint]);


    const selectApe = async function (image: string | undefined, uri: string | undefined, mint:  string | undefined) {
        if (leftClick && image && uri && mint) {
            setTurtleMint(mint);
            if (potion !== image) {
                setLeft(image);
            }
        } else {
            setType("potion");
            if (image && uri && mint) {
                console.log("mint:" +mint);
                setPotionMint(mint);
                console.log("potion:" + selectedPotion)
                console.log("turtle:" + selectedMint)
                if (turtle !== image) {
                    setRight(image);
                }
            }
        }
    }

    const closePopup = function() {
        togglePopup(false);
    }

    return(
        <>
        { publicKey ? 
            <div className="App">
                <div>
                    <div className="mint-container" style={{marginTop: '40px'}}>
                        <DesContainer>
                            <NFT elevation={3}>
                                <Image src={turtle} alt="Select Ape"/>
                                <br/>
                                <br/>
                                <CTAButton style={{marginTop: '20px'}}
                                        disabled={false}
                                        onClick={async () => {
                                                setType("turtle");
                                                setLeftClick(true);
                                                togglePopup(true);
                                        }}
                                        variant="contained"
                                >
                                    {turtle !== './turtle.jpg' ? "Change Turtle" : "Select Your Turtle"}
                                </CTAButton>
                            </NFT>
                        </DesContainer>
                        <div></div>
                        <DesContainer>
                            <NFT elevation={3}>
                                <Image src={potion} alt="Select Potion"/>
                                <br/>
                                <br/>
                                <CTAButton style={{marginTop: '20px'}}
                                        disabled={false}
                                        onClick={async () => {
                                                setType("potion");
                                                setLeftClick(false);
                                                togglePopup(true);
                                        }}
                                        variant="contained"
                                >
                                    {potion !== './potion.jpg' ? "Change Potion" : "Drink Your Potion"}
                                </CTAButton>
                                <br/>
                            </NFT>
                        </DesContainer>
                    </div>
                    <div>
                        <SendTokens mint={selectedMint} potion={selectedPotion} upgradeDisabled={upgradeDisabled} unselect={unselect}/>
                    </div>
                </div>
                { popupIsVisible && options ? <SelectApe type={selectedType} options={options} onClick={closePopup}
                                                        selectApe={selectApe}></SelectApe> : <div></div>}
            </div>
        : null }
    </>
    );
}
