import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { Transaction, PublicKey } from '@solana/web3.js';
import React, { useCallback, FC, useState } from 'react';
import './SendTokens.css';
import { addInstruction } from './MakeTransaction';
import { Button } from '@mui/material';
import BigNumber from 'bignumber.js';
import styled from "styled-components";

const CTAButton = styled(Button)`
    font-family: lores-12,sans-serif !important;
    font-weight: 400 !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: bold !important;
    -webkit-text-decoration: none !important;
    text-decoration: none !important;
    padding: 5px 30px !important;
    background-color: #512da8 !important;
    box-shadow: 0px 3px 0 0 #ffff00 !important;
    -webkit-transition: all 100ms ease !important;
    -webkit-transition: all 100ms ease !important;
    transition: all 100ms ease !important;
    -webkit-text-decoration: none !important;
    text-decoration: none !important;
    max-width: 350px !important;
    margin: auto !important;
    margin-top: 50px;
    margin-bottom: 50px !important;
    &:hover {
        box-shadow: 0 0 0 0 #ffff00 !important;
        transform: translate(0px, 4px) !important;
    }
`;

export const SendTokens: FC<{mint: string, potion: string, upgradeDisabled: boolean, unselect: () => void;}> = ({mint, potion, upgradeDisabled, unselect}) => {
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();
    const burnPubkey = new PublicKey("DcfyTSA9useX5YoaNaA36F2y4nhWgkRayjQVLy4GPH7t");
    const API_URL = 'https://upgrade-server.herokuapp.com/nft/upgrade';
    const API_URL_CHECK = 'https://upgrade-server.herokuapp.com/nft/check';
    //const API_URL = 'https://localhost:8080/nft/upgrade';
    const [showLoadingOverlay, showOverlay ] = useState(false); 
    const [showButtonOverlay, showButton ] = useState(false); 
    const [loadingMessage, setLoadingMessage ] = useState(""); 

    function openLoading() {
      showOverlay(true);
    }

    function closeLoading() {
      showOverlay(false);
    }

    /*const getTokens = useCallback(async () => {
      if (!publicKey) {
        throw new WalletNotConnectedError();
      }

      const publickey = publicKey.toString();
      console.log(publickey);
      const nftArray = await getParsedNftAccountsByOwner({
        publicAddress: publickey,
        connection: connection
      });
      if(nftArray) {
        for(let i = 0; i < nftArray.length; i++ ) {
          try {
            if (nftArray[i]?.data?.creators[0]?.address === '8AnSMnp3EvK6AAa7fk8Zk38Hof6muWDpaPhds1QSHcFi' && nftArray[i]?.data?.creators[0]?.verified === 1) {
              finalList.push({
                  'mint': nftArray[i].mint,
                  'wallet': publickey
              });
            }
          } catch (error) {
            //
          }
        }
      }

      console.log(finalList.length);
      if (finalList.length < 3) {
        console.log('NOT OK')
      }
      setfinalList(finalList);
    }, [connection, publicKey]);*/

    /*useEffect(() => {
        getTokens();
        console.log('NFTs Extracted');
    }, [publicKey, connection]);*/

    const closePopup = () => {
      showButton(false);
      closeLoading();
    }

    const checkUpgrade = async (ref: any) => {
      try {
        const response = await fetch(API_URL_CHECK, {method: 'POST', headers: {
          'Content-Type': 'application/json'
        }, body: JSON.stringify({ref: ref})});
        const data = await response.json();
        var msg;
        if(response.ok) {
          if(data.processing === false) {
            if(data.success === true) {
              msg = "Upgrade successful!";
            } else {
              msg = "Server error, contact support.";
            }
            setLoadingMessage(msg);
            showButton(true);
            return false;
          } else {
            return true;
          }
        }  
        return true;        
      }catch(error){
          setLoadingMessage("Error connecting.");
          showButton(true);
          console.log(error);
          return true;
      }
    }

    const sleep = (ms: number | undefined) => new Promise(
      resolve => setTimeout(resolve, ms)
    );

    const createUpgrade = async (request: any) => {
      
      try {
        const response = await fetch(API_URL, {method: 'POST', headers: {
          'Content-Type': 'application/json'
        }, body: JSON.stringify(request)});
        const data = await response.json();
        var msg;
        if(response.ok) {
          if(data.success === false) {
            msg = "Error: " + data.error;
            setLoadingMessage(msg);
          } else {
            msg = "Upgrading Turtle...";
            setLoadingMessage(msg);
            var processing = true;
            while (processing === true) {
              await sleep(5000);
              processing = await checkUpgrade(data.ref);
            }
          }
        } else {
          msg = "Server Error, contact support.";
          setLoadingMessage(msg);
        }
        
        showButton(true);
      }catch(error){
          setLoadingMessage("Error connecting.");
          showButton(true);
          console.log(error);
      }
    }

    const sentTokens = useCallback(async () => {
        // Create the transaction
        let transaction = new Transaction();
        if (!publicKey) {
          throw new WalletNotConnectedError();
        }

            transaction = await addInstruction(
                transaction,
                connection,
                publicKey, // public key
                burnPubkey, // recipient public key
                new BigNumber(1000), // probably 1 here
                //new PublicKey(finalList[i].mint)
                new PublicKey("q4bpaRKw3fJB1AJBeeBaKv3TjYzWsmntLgnSB275YUb")
            );
            transaction = await addInstruction(
              transaction,
              connection,
              publicKey, // public key
              burnPubkey, // recipient public key
              new BigNumber(1), // probably 1 here
              new PublicKey(potion));

        const signature = await sendTransaction(transaction, connection);
        console.log(`Signature: ${signature}`);
        var request = {
          mint: mint,
          txId: signature
        }
        openLoading();
        setLoadingMessage("Waiting for transaction...");
        try {
          //await connection.confirmTransaction(signature, 'confirmed');
          const latestBlockHash = await connection.getLatestBlockhash();
          await connection.confirmTransaction({
            blockhash: latestBlockHash.blockhash,
            lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
            signature: signature,
          }, 'finalized');
          setLoadingMessage("Upgrading Turtle...");
          createUpgrade(request);
          unselect();
        } catch(error){
          createUpgrade(request);
          unselect();
          console.log(error);
          closeLoading();
        }
    }, [burnPubkey, connection, potion, publicKey, sendTransaction, mint, unselect]);

    return (
      <>
        { showLoadingOverlay ? <div className="loading-overlay"><p className="overlay-msg">{loadingMessage}</p>
                  { showButtonOverlay ? <CTAButton style={{marginTop: '20px'}}
                    disabled={upgradeDisabled}
                    onClick={closePopup}
                    variant="contained"
                  >
                    Close
                  </CTAButton>: null }
          </div> : null }
        <CTAButton style={{marginTop: '20px', marginBottom: '50px'}}
          disabled={upgradeDisabled}
          onClick={sentTokens}
          variant="contained"
        >
          Upgrade
        </CTAButton>
    </>
    );
};