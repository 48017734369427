import React, { FC , useEffect, useState} from 'react';
// import logo from "./logo.svg";
import "./App.css";
import "./styles.css";
// require('@solana/wallet-adapter-react-ui/styles.css');
import {
  ConnectionProvider,
  useConnection,
    useWallet,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
    TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from "@solana/web3.js";
import MyWallet from "./components/WalletConnection/WalletConnection";
import { SendTokens } from './components/SendTokens/SendTokens';
import twitterLogo from './assets/twitter-logo.svg';
import discordLogo from './assets/discord.png';

import styled from "styled-components";
import { Paper, Button } from '@mui/material';
import axios from "axios";
import { SelectApe } from "./components/Select/SelectApe";
import type { Options } from '@nfteyez/sol-rayz';
import {Upgrade } from './components/Select/Upgrade';


const App = () => {
    {
        // Can be set to 'devnet', 'testnet', or 'mainnet-beta'
        const network = WalletAdapterNetwork.Devnet;

        // You can also provide a custom RPC endpoint
        const endpoint = 'https://withered-snowy-grass.solana-mainnet.quiknode.pro/fc2432b88cc438781efabb5073e6d8e6d839e862/';

        // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking --
        // Only the wallets you configure here will be compiled into your application
        const wallets = React.useMemo(
            () => [
                new PhantomWalletAdapter(),
                new SlopeWalletAdapter(),
                new SolflareWalletAdapter({network}),
                new TorusWalletAdapter(),
                new LedgerWalletAdapter(),
                new SolletWalletAdapter({network}),
                new SolletExtensionWalletAdapter({network}),
            ],
            [network]
        );

        return (
            <div className="top-wrapper">
                <ConnectionProvider endpoint={endpoint}>
                    <WalletProvider wallets={wallets}>
                        <MyWallet/>
                        <Upgrade></Upgrade>
                    </WalletProvider>
                </ConnectionProvider>
                <footer className="custom-footer">
                    <img src="./logo.png" alt="sundayslab" className="footer-img"></img> <p className="footer-txt">Powered by Sundays Lab</p>
                </footer>
            </div>
        );
    }
    ;
}
export default App;
