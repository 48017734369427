import { NftTokenAccount, useWalletNfts } from "@nfteyez/sol-rayz-react";
import type { Options } from "@nfteyez/sol-rayz";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { constants } from "perf_hooks";

export const SelectApe = ({
    type,
    options,
    onClick,
    selectApe
}: {
type: string;
options: Options;
onClick: () => void;
selectApe: (image: string | undefined, uri: string | undefined, mint: string | undefined) => void;
}) =>  {

    const [arr, setData] = useState<any | null>(null);
    const [loading, setLoading] = useState<any | null>(null);

    const { nfts, isLoading, error } = useWalletNfts(options);
    let res;
    useEffect(() => {
        async function data() {
            setLoading(true);
            try {
                res = await getNftTokenData();
            } catch (error) {
                console.log(error);
            }
        }
        data();
        }, [nfts]);

    const getNftTokenData = async () => {
            const arr = [];
            const n = nfts.length;
            let creator;
            console.log("type: " + type)
            if(type==="potion") {
                creator = "7YKnna1QCnYPmyGjCXpBxzrTryJnAqWpTrR2z5keh1as" //"7YKnna1QCnYPmyGjCXpBxzrTryJnAqWpTrR2z5keh1as"
            } else {
                creator = "4kUqMiYz28TgDf7j3zRdXJBXF9k6kJG7QzrnJMAvNiSR" //4kUqMiYz28TgDf7j3zRdXJBXF9k6kJG7QzrnJMAvNiSR
            }
            for (let i = 0; i < n; i++) {
                if(nfts[i].data?.creators){
                    if(nfts[i].data?.creators[0].address===creator){
                        const val = await axios.get(nfts[i].data.uri);
                        nfts[i].metadata = val;
                        if(type) {
                            if( nfts[i].metadata.data.attributes.findIndex((e: any) => e.value === "Generation 2") === -1 || type === "potion"){
                                var data = val.data;
                                data.url = val.config.url;
                                data.mint = nfts[i].mint;
                                arr.push(data);
                            }
                        } else {
                            arr.push(val);
                        }
                        setData(arr);
                    }
                }
            }
            setLoading(false);
            return arr;
    };

    /*const listItems = arr.map((val) =>
        <img src={val.data.image} alt="ape" onClick={() => selectApe(val.data.image, val.uri)}></img>
    );*/

    if (error) return <div>Have some error</div>
    if (isLoading) return <div>Loading...</div>

    //{!loading && arr && arr.map((val: { config: { url: string | undefined; }; data: { image: string | undefined; }; }, ind: any) => {

    return (
          <>
          <div className="overlay" onClick={onClick}>
            <div className="popup-grid">
                    {!loading && arr && arr.map((item: { image: string | undefined; url: string | undefined; mint: string | undefined; }, i: any) => {

                        return (
                                <img src={item.image} key={i} alt="ape" onClick={() => selectApe(item.image, item.url, item.mint)}></img>
                        );
                    })
                }
            </div>
          </div>
        </>
    );
}
